import React, { FC } from 'react';
import Accordion from 'react-tiny-accordion';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import Button from 'common/Button';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import IconCustom from 'common/IconCustom';
import { ControledAccordionProps } from './model';
import './ControledAccordion.scss';
import './ControledAccordionRTL.scss';

const ControledAccordion: FC<ControledAccordionProps> = ({ className, items, selectedIndex }) => {
  const index = selectedIndex
    ? selectedIndex >= items.length
      ? items.length - 1
      : selectedIndex - 1
    : null;

  return (
    <Accordion
      className={classNames(`accordion`, className)}
      transitionDuration="300"
      {...(index !== null && { selectedIndex: index })}
    >
      {items?.length
        ? items.map(({ properties: { answer, question } }) => (
            <div
              className="accordion__answer"
              key={question}
              data-header={
                <Button classes="accordion__question">
                  <IconCustom className="accordion__chevron-icon" icon="arrow-down" />
                  <span>{question}</span>
                </Button>
              }
            >
              <DangerouslySetInnerHtml html={answer} />
            </div>
          ))
        : null}
    </Accordion>
  );
};

export default ControledAccordion;

export const query = graphql`
  fragment FragmentAccordionSection on AccordionSectionFAQTypes {
    properties {
      subtitle
      title
      faqAccordionSection {
        properties {
          answer
          question
        }
      }
    }
  }
`;
